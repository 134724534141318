<template>
<div class='quote'>
  <div v-if='displayCountdown' class='countdown'>
    <circle-progress :percent="100"
      :size='64'
      :lineWidth='8'
      :fillColor='countdownDisplay.color'
      empty-color='rgba(0,0,0,0.0)'
      :duration='countdownTime'
      :showText='false'/>
  </div>
  <div class='body'>
    <span v-if='!blockAssholes' >{{quoteBody}}</span>
    <div v-else class='blocked-quote' ><font-awesome-icon :icon="['fal', 'ban']" /></div>
  </div>
  <div class='author'>
    <span v-if='!unknownAuthor' >&mdash; {{quoteAuthor}}</span>
    <span v-else style='font-type:italic' >&mdash; Unknown</span>
  </div>
</div>

</template>

<script>
import moment from "moment"
import AirisNotifier from '../src/airis-notifier'
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { useIntervalFn } from '@vueuse/core'

const axios = require('axios')

const quoteFetchIntervalms = 20*60*1000

export default {
  name: 'quote',
  components: {
   CircleProgress
  },
  data: () => {
  return {
    quoteBody: '',
    quoteAuthor: '',
    unknownAuthor: false,
    blockAssholes: false,
    newQuote: true,
    displayCountdown: false,
    remainingTime: 0,
    countdownTime: 10000,
    countdownIntervalms: 1000,
    countdownDisplay: {
      nominalColor: 'rgb(8,170,199,0.2)',
      errorColor: 'rgb(252,96,66,0.2)',
      color: 'rgb(8,170,199,0.2)',
    }
  }
  },
  computed: {

  },
  methods: {
    fetchQuote() {
      let that = this
      that.countdownDisplay.color = that.countdownDisplay.nominalColor
      const url = 'www.quoterism.com/api/quotes/random'
      const proxyURL = `/proxy/reverse?url=${encodeURIComponent(url)}`

      axios.get(proxyURL)
      .then((result) => {
        let data = result.data.random

        if(typeof data == 'string'){
          that.$rollbar.captureEvent({ quote: data }, 'info')
          data = JSON.parse(data.replace("\\'", "'"))
        }
        const quoteText = data.text
        const quoteAuthor = data.author.name

        that.unknownAuthor = (_.trim(quoteAuthor) == '')

        that.blockAssholes = quoteAuthor.includes('Trump')

        that.quoteAuthor = quoteAuthor
        that.quoteBody = quoteText
        that.newQuote = true
        that.remainingTime = quoteFetchIntervalms
        that.displayCountdown = false
      })
      .catch((axiosError) => {
        const error = axiosError.isAxiosError && axiosError.response !== undefined ? axiosError.response : axiosError
        const errorData = error.data || ""
        const errorType = error.status || ""

        AirisNotifier.error( "Quote Retrieval Error", error );
        that.remainingTime = quoteFetchIntervalms
        that.countdownDisplay.color = that.countdownDisplay.errorColor
      })
    },
    pollForQuote() {
      this.remainingTime -= this.countdownIntervalms
      if(!this.displayCountdown && (this.remainingTime - this.countdownIntervalms) <= this.countdownTime ){
        this.displayCountdown = true
      }
      if(this.remainingTime <= 0){
        this.fetchQuote()
      }
    }
  },
  mounted() {
    const { pause, resume, isActive } = useIntervalFn(() => {
      this.pollForQuote();
    },  this.countdownIntervalms, {immediateCallback: true});
  },
}
</script>

<style lang="scss">
.blocked-quote {
  color: rgb(68, 68, 68);
  width: 100%;
  padding-top: 6px;
  border-radius: 8px;
  background: #fafafa;
  text-align: center;
}
</style>
