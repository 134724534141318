<template>
  <div class="container">
<div class="row">
  <div class="col-md-12 background action start">
    <div id='time-component' class="full">
      <div class='time'>
        <div class='current'>{{currentTime}}</div>
        <div class='date mt-4'>{{currentDate}}
          <font-awesome-icon :icon="['fad', 'flask']" class='experiment' v-if="experiment"/>
          <font-awesome-icon :icon="['fad', 'flux-capacitor']" class='debug' v-if="debug"/>
          <span class="fa-stack" v-if="noLAN">
            <font-awesome-icon :icon="['fad', 'network-wired']" class='fa-stack-1x' style='color:#888' />
            <font-awesome-icon :icon="['far', 'ban']" class="fa-stack-2x debug" style="opacity:0.8" />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div id='upcoming-chores'>
  <chores />
  <quote />
</div>
<face-detector v-if="experiment"/>
<div class='row' v-if="experiment">
  <div class="col-md-6">
<calendar calendar-grouping='Dave' v-bind:calendar-ids='["dpitman@gmail.com"]' class='float-start' />
</div>
<div class="col-md-6">
<calendar calendar-grouping='Em' v-bind:calendar-ids='["emsque.ifa@gmail.com"]'  class='float-end'/>
</div>
</div>

<div class="row">
  <div class="col-md-12">
    <div id='transit-component'>
      <!-- <transit route-id='1_102574' stop-id='1_19340' route-name='40' v-bind:lateMin="2" v-bind:warningMin="4" v-bind:leaveMin="6" class='float-start' /> -->
      <transit route-id='1_100224' stop-id='1_18120' route-name='44' v-bind:lateMin="8" v-bind:warningMin="11" v-bind:leaveMin="14" class='float-end' />
    </div>
  </div>
</div>
<div class="bottom">
  <div class="row">
    <div class="col-md-12">
      <div id='climate-component'>
        <climate v-bind:clientIDs="['minisplit_leo', 'minisplit_office', 'minisplit_snug']"/>
      </div>
    </div>
  </div>
<div class="row">
  <div class='col-md-12'>
    <div id="solar">
      <solar/>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div id='weather-report'>
      <weather />
    </div>
  </div>
</div>
</div>
</div>
</template>

<script>
import Hammer from 'hammerjs'

import "jsonp"
import { DateTime as LuxonDateTime } from 'luxon'
import { useIntervalFn } from '@vueuse/core'

// Import Vue components
import Quote from '../components/Quote'
import Weather from '../components/Weather'
import Transit from '../components/Transit'
import Chores from '../components/Chores'
import FaceDetector from '../components/FaceDetector'
import Calendar from '../components/Calendar'
import Solar from '../components/Solar'
import Climate from '../components/Climate'

export default {
  name: 'app',
  components: {
    Quote,
    Weather,
    Transit,
    Chores,
    FaceDetector,
    Calendar,
    Solar,
    Climate,
  },
  data: () => {
  return {
    currentTime: '',
    currentDate: '',
  }
  },
  computed: {

  },
  methods: {
    timestamp() {
      this.currentTime = LuxonDateTime.now().toLocaleString(LuxonDateTime.TIME_SIMPLE)
      this.currentDate = LuxonDateTime.now().toLocaleString({ month: 'short', day: '2-digit' });
    },
  },
  mounted: function () {
    this.$nextTick(function () {

    })
    const { pause, resume, isActive } = useIntervalFn(() => {
      this.timestamp();
    }, 1000, {immediateCallback: true});
  }
}
</script>

<style lang="scss">
@import '../css/app.scss';

</style>
