<template>
  <div class="climate">
    <div v-if='this.error.display' class='row' style="margin-bottom:16px">
      <div class='col-md-12'>
        <div class='mx-auto'>
          <div v-if="false">
          <div class='icon title error'><font-awesome-icon :icon="['fal', 'engine-warning']" /> </div>
          <h4 style='display: inline'>{{ '' }}</h4>
        </div>
          <div v-if='this.error.modeConflict' >
            <span class="fa-stack fa-2x">
              <i class="fa-solid fa-camera fa-stack-1x"></i>
              <font-awesome-icon :icon="['fal', 'air-conditioner']" class="fa-stack-1x" />
              <font-awesome-icon :icon="['far', 'ban']" class="fa-stack-2x" style="color:Tomato" />
            </span>
            <h2 style='display: inline'>Heat/Cool Conflict</h2>
          </div>
        </div>
      </div>
    </div>
    <div class='row' style="margin-bottom:16px;" v-for="(minisplit, clientID) in minisplits">
      <div class="col-md-3 minisplit" style="margin-right:8px;" >
        <div :class='`row row-no-gutters status ${minisplit.mode}`'>
          <div class="col-md-5 padding-right-0">
            <h4 style="text-align: center;">{{ minisplit.name }}</h4>
            <h2 style="margin-top:8px">
              <font-awesome-icon :icon="getIcon(clientID)" class="lead-icon center-block" />
            </h2>
            <h4 class="center-block temp target" v-if="minisplit.temp.current" ><font-awesome-icon :icon="['far', 'thermometer-half']" class="inline-block" /> {{ minisplit.temp.current }}°</h4>
          </div>
          <div class="col-md-7 padding-left-0" style="margin-top:42px;">
            <h2 class='' v-if="minisplit.temp.target && minisplit.mode != 'off'" >{{ minisplit.temp.target }}°</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
// import { useMQTT } from 'mqtt-vue-hook'

import mqtt from "mqtt";

var protocol = 'ws'
var host = 'homeassistant.ehouse'
var port = 8083

// var protocol = 'tcp'
// var host = 'homeassistant.local'
// var port = 1883


export default {
  name: 'Climate',
  props: {
    clientIDs: Array,
  },
  data: () => {
    return {
      error: {
        modeConflict: true,
        display: true,
      },
      clients: {},
      minisplits: {
        '1': { name: 'Office', mode: 'heat', fan: { mode: 'auto', speed: 0 }, temp: { target: 72, current: 72 }, vaneVertical: { mode: 'auto', angle: 0 }, vaneHorizontal: { mode: 'auto', angle: 0 } },
        '2': { name: 'Living', mode: 'cool', fan: { mode: 'auto', speed: 0 }, temp: { target: 72, current: 72 }, vaneVertical: { mode: 'auto', angle: 0 }, vaneHorizontal: { mode: 'auto', angle: 0 } },
        '3': { name: 'Leo', mode: 'dry', fan: { mode: 'auto', speed: 0 }, temp: { target: 72, current: 72 }, vaneVertical: { mode: 'auto', angle: 0 }, vaneHorizontal: { mode: 'auto', angle: 0 } },
        '4': { name: 'Snug', mode: 'fan', fan: { mode: 'auto', speed: 0 }, temp: { target: 72, current: 72 }, vaneVertical: { mode: 'auto', angle: 0 }, vaneHorizontal: { mode: 'auto', angle: 0 } },
        '5': { name: 'Master', mode: 'unknown', fan: { mode: 'auto', speed: 0 }, temp: { target: 72, current: 72 }, vaneVertical: { mode: 'auto', angle: 0 }, vaneHorizontal: { mode: 'auto', angle: 0 } },
      }, // Example value, replace with your actual data
    }
  },
  methods: {
    getIcon(clientId) {
      switch (this.minisplits[clientId].mode) {
        case 'heat':
          return ['fal', 'sun'];
        case 'cool':
          return ['fal', 'snowflake'];
        case 'dry':
          return ['fal', 'tint'];
        case 'fan':
          return ['fal', 'fan'];
        case 'idle':
          return ['fal', 'power-off'];
        case 'off':
          return ['fal', 'power-off'];
        case 'unknown':
          return ['fal', 'question-circle'];
        default:
          return ['fal', 'error-circle'];
      }
    },
    detectModeConflict() {
      var modes = []
      for (var [clientID, minisplit] of Object.entries(this.minisplits)) {
        modes.push(minisplit.mode)
      }
      if (modes.includes('heat') && modes.includes('cool')) {
        this.error.modeConflict = true
      } else {
        this.error.modeConflict = false
      }
    },
    parseIDFromMessage(topic) {
      // 'homeassistant/climate/minisplit-master/'
      // or 'minisplit_master/climate/minisplit_master/...'
      topic = topic.split('/')
      if (topic[0] == 'homeassistant') {
        return topic[2].replace('-', '_')
      } else {
        return topic[2]
      }
      return topic
    },
    parseTopicFromMessage(topic) {
      // 'homeassistant/climate/minisplit-main-floor/minisplit_main_floor/config'
      return topic.split('/').slice(-2)
    },
    getTempInF(temp) {
      return parseFloat((( 9.0 / 5.0 ) * temp + 32.0 ).toFixed(2));
    }
  },
  mounted() {
    const store = useStore()
    // Connect to the mqtt broker
    let mqttClientURL = `${protocol}://${host}:${port}/mqtt`
    if(this.debug){
      console.log("Initiating MQTT Client", mqttClientURL)
      if(this.noLAN){
        console.warn("LAN disabled via ?LAN=false, not connecting to MQTT Broker")
      }
    }
    this.mqttClient = mqtt.connect(mqttClientURL, {
      clean: true,
      protocolVersion: 5,
      keepalive: 60,
      clientId: `AIRIS_${Math.random().toString(16).substring(2, 10)}`,
      connectTimeout: 1000,
      username: document.head.querySelector("meta[name=mqtt_user]").content,
      password: document.head.querySelector("meta[name=mqtt_pass]").content,
      manualConnect: this.noLAN,
    });

    this.clients =Object.assign({}, ...this.clientIDs.map((clientID) => ({[clientID]: { config: {}, state: {}}})));
    this.minisplits =Object.assign({}, ...this.clientIDs.map((clientID) => ({[clientID]: { 
      name: null, 
      mode: 'unknown', 
      fan: { mode: null, speed: null }, 
      temp: { target: null, current: null }, 
      vaneVertical: { mode: null, angle: 0 }, 
      vaneHorizontal: { mode: null, angle: 0 },
      lastUpdate: null
    }})));
    var that = this;
    this.mqttClient.on("message",  (topic, message) => {
      
      var clientID = that.parseIDFromMessage(topic)
      var topic = that.parseTopicFromMessage(topic)
      var data = message.toString()
      if(that.debug){
        console.log("Message", clientID, topic, data)
      }
      if (topic[1] == 'config' || topic[0] == 'config') {
        data = JSON.parse(data)
        if(that.debug){
          console.log(clientID, topic, data)
        }
        that.clients[clientID].config = data
        that.minisplits[clientID].name = data.name.split(' ')[1] 
      } else if (topic[0] == 'current_temperature') {
        that.minisplits[clientID].temp.current = this.getTempInF(data)
      } else if (topic[0] == 'target_temperature') {
        that.minisplits[clientID].temp.target = this.getTempInF(data)
      } else if (topic[0] == 'mode' && topic[1] == 'state') {
        that.minisplits[clientID].mode = data
        that.detectModeConflict()
      }
    })

    this.mqttClient.on("connect", () => {
      for (const clientID of this.clientIDs) {
        if(this.debug){
          console.log("Subscribing to Minisplit", clientID)
        }
        // store.dispatch('climate/watchMinisplit', clientID)
        var climateTopic = `+/climate/${clientID}/#`;

        var haClientName = clientID.replace('_', '-')
        var haTopic = `homeassistant/climate/${haClientName}/#`
        that.mqttClient.subscribe(
          climateTopic,
        (err, granted) => {
          
        },
        clientID
        )
        that.mqttClient.subscribe(
          haTopic,
        (err, granted) => {
          
        },
        haClientName
        )
      }
    })

    
  },
}
</script>

<style scoped>
.climate {
  margin-bottom: 60px;
  opacity: 0.6;
}
.lead-icon {
  font-size: 2em;
  color: #ffffff;
}
.minisplit{
  .lead-icon {
      font-size: 1.5em;
      color: #ffffff;
    }
  .status {
    &.off {
      opacity:0.4;
    }
  }

  .temp {
    &.target {
      text-align: center;
      color: #ffffff;
      overflow:visible;
    }
  }
}
</style>
